<template>
    <div class="choice-container" >
    <ion-radio-group :value="this.reset">
        <div v-for="item in choices" :key="item.id"> 

            <div class="choice-option"> 
                <ion-radio  class="choice-option"  @click="this.answer = item.text"> 
                </ion-radio> 
            </div>
            <span class="choice-text"> {{item.text}}  </span>
        </div>
    </ion-radio-group>

    <button class="next-question-button-disabled requests-accept-button"  v-if="this.answer==null"> Next </button>
    <button class="next-question-button create-consultant-button requests-accept-button" v-if="this.answer!=null"  @click="sendAnswer()"> Next </button>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {IonRadio} from "@ionic/vue";

export default defineComponent({
  name: "SingleChoice",
   props: ['choices'],
  data() {
    return {
        answer : null,
        reset : null,
    };
  },
  components: {
      IonRadio
  },
  methods: {
    sendAnswer(){
      this.$emit('answeredSingle',  this.answer)
      this.answer = null;
    this.reset = null;
    }
  },

  async created() {
      
  },
});
</script>