<template>
    <div class="general-rating-container" >
    <div>
        <div class="starrating-stars" @click="saveAnswer(1)" checked="true" >
           <i class="icon ion-ios-star" v-if="this.answer>=1"> </i>
           <i class="icon ion-ios-star-outline" v-else> </i>
        </div>

        <div class="starrating-stars"  @click="saveAnswer(2)" checked="true" >
           <i class="icon ion-ios-star" v-if="this.answer>=2"> </i>
           <i class="icon ion-ios-star-outline" v-else> </i>
        </div>

          <div class="starrating-stars"  @click="saveAnswer(3)" checked="true" >
           <i class="icon ion-ios-star" v-if="this.answer>=3"> </i>
           <i class="icon ion-ios-star-outline" v-else> </i>
        </div>

          <div class="starrating-stars"  @click="saveAnswer(4)" checked="true" >
           <i class="icon ion-ios-star" v-if="this.answer>=4"> </i>
           <i class="icon ion-ios-star-outline" v-else> </i>
        </div>

        <div class="starrating-stars"  @click="saveAnswer(5)" checked="true" >
           <i class="icon ion-ios-star" v-if="this.answer>=5"> </i>
           <i class="icon ion-ios-star-outline" v-else> </i>
        </div>

    </div>

    <button class="next-question-button-disabled requests-accept-button"  v-if="this.answer==0"> Next </button>
    <button class="next-question-button create-consultant-button requests-accept-button" v-if="this.answer>=1"  @click="sendAnswer()"> Next </button>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "StarRating",
   props: ['choices'],
  data() {
    return {
      answer : 0,
    };
  },
  components: {
  },
  methods: {
    saveAnswer(score){
      this.answer = score;
    },
    sendAnswer(){
      this.$emit('answeredStars',  this.answer)
      this.answer = 0;
    }
  },

  async created() {
      
  },
});
</script>