<template>
    <div class="general-rating-container" >
    <div>
	<label class="item item-input item-floating-label">
            <textarea class="text-area" v-model="this.answer" placeholder="Write here"></textarea>
	</label>
    </div>
    <button class="next-question-button create-consultant-button requests-accept-button" @click="sendAnswer()"> Next </button>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "StarRating",
   props: ['choices'],
  data() {
    return {
      answer : null,
    };
  },
  components: {
  },
  methods: {
    sendAnswer(){
      this.$emit('answeredFreeForm', this.answer)
      this.answer = null;
    }
  },

  async created() {
      
  },
});
</script>