<template>
    <div class="choice-container" >
        <div v-for="item in choices" :key="item.id"> 

            <div class="choice-option" > 
                <ion-checkbox class="choice-option"  @click="editAnswer(item.text)"> 
                </ion-checkbox> 
            </div>
            <span class="choice-text"> {{item.text}}  </span>
        </div>

    <button class="next-question-button create-consultant-button requests-accept-button" @click="sendAnswer()"> Next </button>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "MultipleChoice",
   props: ['choices'],
  data() {
    return {
        answer : null,
        answerArray : [],
    };
  },
  components: {
  },
  methods: {

    editAnswer(answer){
      console.log(answer)
      let removed = false;
      for(let i = 0; i<this.answerArray.length; i++){
        if(this.answerArray[i] == answer){
           this.answerArray.splice(i, 1);
           removed = true;
        }
      }
      if (!removed) {
      this.answerArray.push(answer);
      }


    },
    sendAnswer(){
      this.answer = this.answerArray;
      this.$emit('answeredMultiple',  this.answer)
      this.answer = null;
    }
  },

  async created() {
      
  },
});
</script>