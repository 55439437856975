<template>
  <base-layout>
    <ion-content class="has-header has-subheader has-bottom-banner">

    <template v-if="!currentQuestion">
     <div class="general-rating-container" v-for="poll in polls" :key="poll.id"> 
       <div> {{poll.title}} </div>
      <button class="next-question-button create-consultant-button requests-accept-button" @click="startQuestions(poll)"> Start </button>
     </div>
    </template>

    <template v-if="currentQuestion && !noMoreQuestions">
      <div>
     <div class="general-rating-container" v-html="currentQuestion[0].text">   </div> 
      <StarRating v-on:answeredStars="questionAnswered" v-if="currentQuestion[0].type == 'starrange'"> </StarRating>
      <FreeForm v-on:answeredFreeForm="questionAnswered" v-if="currentQuestion[0].type == 'freeform'"> </FreeForm>
      <SingleChoice :choices="currentQuestion[0].choices" v-on:answeredSingle="questionAnswered" v-if="currentQuestion[0].type == 'single'"> </SingleChoice>
      <MultipleChoice :choices="currentQuestion[0].choices" v-on:answeredMultiple="questionAnswered" v-if="currentQuestion[0].type == 'multiple'"> </MultipleChoice>
    </div>
    </template>


    <template v-if="currentQuestion && noMoreQuestions">
        <div class="general-rating-container" >
        <div > Thank you for your time! </div> 
       <button class="next-question-button create-consultant-button requests-accept-button" @click="returnToSession()"> Return to session </button>
        </div>
    </template>


    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
//import MultipleChoice from "../../components/polls/MultipleChoice.vue";
import StarRating from "../../components/polls/StarRating.vue";
import FreeForm from "../../components/polls/FreeForm.vue";
import SingleChoice from "../../components/polls/SingleChoice.vue";
import MultipleChoice from "../../components/polls/MultipleChoice.vue";
import {mapActions} from "vuex";

export default defineComponent({
  name: "PollDetail",
  data() {
    return {
      polls: [],
      questions: [],
      currentQuestion : null,
      sessionId : null,
      noMoreQuestions : false,
      params: null,
    };
  },
  components: {
    IonContent,
    MultipleChoice,
    StarRating,
    FreeForm,
    SingleChoice,
  },
  methods: {
    ...mapActions('polls', ['getAllPolls', 'getPollById', 'getAllQuestions', 'getQuestionById', 'saveAnswers']),

    async questionAnswered(answer){
      let question = this.currentQuestion[0];

      this.currentQuestion = this.questions.filter(obj => {
        return obj.id === question.next
      })

      if(question.next==''){
        this.noMoreQuestions = true;
      }

        let payload = {question: question, answer: answer, params: this.params}

      this.saveAnswers(payload) //every time a question is answered and the user goes to the next question, the data is sent to the st
    },

    startQuestions(poll){
      this.currentQuestion = this.questions.filter(obj => {
        return obj.id === poll.next
      })
    },

    returnToSession(){
      window.location.href ="#/app/sessions/"+this.sessionId;
    },
  },

  async created() {
      
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Poll Detail') {
          this.polls = await this.getAllPolls();
          this.questions = await this.getAllQuestions();

          this.sessionId = route.params.ref_id
          this.params = {id: route.params.id, ref_type: route.params.ref_type, ref_id: route.params.ref_id}

        }
      }
    }
  }
});
</script>